import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

export default function RawDataDisplay({ title, data }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>{title}</AccordionSummary>
      <AccordionDetails>
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </AccordionDetails>
    </Accordion>
  );
}
