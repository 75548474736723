import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import RawDataDisplay from "./RawDataDisplay";
import { createTodo, deleteTodo, updateTodo } from "../graphql/mutations";
import { listTodos } from "../graphql/queries";

const initialState = { name: "", description: "", id: "" };

const useStyles = makeStyles((theme) => ({
  container: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  todo: { marginBottom: 15 },
  // input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: "bold" },
  todoDescription: { marginBottom: 0 },
  // button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}));

const App = () => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    fetchTodos();
  }, []);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listTodos));
      const todos = todoData.data.listTodos.items;
      console.log(todos);
      setTodos(todos);
    } catch (err) {
      console.log("error fetching todos");
    }
  }

  async function addOrUpdateTodo() {
    try {
      if (!formState.name || !formState.description) return;
      const todo = { ...formState };
      setFormState(initialState);
      if (todo.id !== "") {
        const results = await API.graphql(
          graphqlOperation(updateTodo, {
            input: {
              id: todo.id,
              name: todo.name,
              description: todo.description,
            },
          })
        );
        const filteredTodos = todos.filter((item) => item.id !== todo.id);
        setTodos([...filteredTodos, results.data.updateTodo]);
      } else {
        const results = await API.graphql(
          graphqlOperation(createTodo, {
            input: { name: todo.name, description: todo.description },
          })
        );
        setTodos([...todos, results.data.createTodo]);
      }
    } catch (err) {
      console.log("error creating or updating todo:", err);
    }
  }

  async function editTodo(id) {
    const todo = todos.find((item) => item.id === id);
    console.log(todo);
    setFormState(todo);
  }

  async function removeTodo(id) {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const results = await API.graphql(
        graphqlOperation(deleteTodo, { input: { id } })
      );
      console.log("removeTodo", results);

      const newTodos = todos.filter((item) => item.id !== id);
      setTodos(newTodos);
    } catch (err) {
      console.log("error removing todo:", err);
    }
  }

  return (
    <div className={classes.container}>
      <h2>Todos</h2>
      <input
        onChange={(event) => setInput("name", event.target.value)}
        className={classes.input}
        value={formState.name}
        placeholder="Name"
      />
      <input
        onChange={(event) => setInput("description", event.target.value)}
        className={classes.input}
        value={formState.description}
        placeholder="Description"
      />
      <input
        className={classes.input}
        value={formState.id}
        disabled
        placeholder="ID"
      />
      <button className={classes.button} onClick={addOrUpdateTodo}>
        Save Todo
      </button>
      {todos.map((todo, index) => (
        <div key={todo.id ? todo.id : index} className={classes.todo}>
          <p className={classes.todoName}>{todo.name}</p>
          <p className={classes.todoDescription}>{todo.description}</p>
          <button
            onClick={() => {
              editTodo(todo.id);
            }}
          >
            Edit
          </button>
          <button
            onClick={() => {
              removeTodo(todo.id);
            }}
          >
            Delete
          </button>
        </div>
      ))}
      <RawDataDisplay title="Raw Todos" data={todos} />
    </div>
  );
};

export default App;
