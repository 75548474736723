/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        priority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      placeId
      description
      line1
      line2
      city
      state
      postalCode
      person {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      kitLastReceived
      createdAt
      updatedAt
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      name
      address {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const listPersons = /* GraphQL */ `
  query ListPersons(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKitRequest = /* GraphQL */ `
  query GetKitRequest($id: ID!) {
    getKitRequest(id: $id) {
      id
      requester {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      recipient {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      description
      priority
      shippedTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listKitRequests = /* GraphQL */ `
  query ListKitRequests(
    $filter: ModelKitRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKitRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        priority
        shippedTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const addressesByPlaceIdKits = /* GraphQL */ `
  query AddressesByPlaceIdKits(
    $placeId: String
    $kitLastReceived: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressesByPlaceIdKits(
      placeId: $placeId
      kitLastReceived: $kitLastReceived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkAddress = /* GraphQL */ `
  query CheckAddress($address: AddressInput) {
    checkAddress(address: $address) {
      addressAvailable
      conflicts {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      message
    }
  }
`;
