/* Format the phone number the way AWS Cognito requires
 *
 * Assumes phone numbers in the +1 country code.
 *
 * Examples of valid input:
 *
 *    +1 (800) 123-456
 *    800-123-456
 *    800123456
 *    1800123456
 */
export const formatPhoneNumber = (input) => {
  const cleaned = input.replace(/[^\d]/g, "");
  if (cleaned.length === 11) {
    return "+" + cleaned;
  } else if (cleaned.length === 10) {
    return "+1" + cleaned;
  } else {
    throw new Error(`Invalid phone number: ${input}`);
  }
};
