import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import * as timeago from "timeago.js";

import { deleteKitRequest, updateKitRequest } from "../graphql/mutations";
import { listKitRequests } from "../graphql/queries";
import RawDataDisplay from "./RawDataDisplay";

const useStyles = makeStyles((theme) => ({
  kitRequest: { marginBottom: 15 },
  // input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  kitRequestName: { fontSize: 20, fontWeight: "bold" },
  kitRequestValue: { marginBottom: 0 },
  // button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}));

const App = () => {
  const classes = useStyles();
  const [kitRequests, setKitRequests] = useState([]);

  useEffect(() => {
    fetchKitRequests();
  }, []);

  async function fetchKitRequests() {
    try {
      const kitRequestData = await API.graphql(
        graphqlOperation(listKitRequests)
      );
      const kitRequests = kitRequestData.data.listKitRequests.items;
      setKitRequests(kitRequests);
    } catch (err) {
      console.log("error fetching kitRequests");
    }
  }

  async function shipKitRequest(id) {
    try {
      const timestamp = new Date();
      const results = await API.graphql(
        graphqlOperation(updateKitRequest, {
          input: {
            shippedTimestamp: timestamp.toISOString(),
            id,
          },
        })
      );
      const filteredKitRequests = kitRequests.filter((item) => item.id !== id);
      setKitRequests([...filteredKitRequests, results.data.updateKitRequest]);
    } catch (err) {
      console.log("error creating or updating kitRequest:", err);
    }
  }

  async function removeKitRequest(id) {
    if (typeof id === "undefined") {
      return;
    }

    try {
      await API.graphql(graphqlOperation(deleteKitRequest, { input: { id } }));

      const updatedKitRequests = kitRequests.filter((item) => item.id !== id);
      setKitRequests(updatedKitRequests);
    } catch (err) {
      console.log("Error removing kitRequest:", err);
    }
  }

  return (
    <>
      <h2>Site KitRequests</h2>
      {kitRequests.map((kitRequest, index) => (
        <div
          key={kitRequest.id ? kitRequest.id : index}
          className={classes.kitRequest}
        >
          <RawDataDisplay
            title={`Request from ${timeago.format(kitRequest.createdAt)}`}
            data={kitRequest}
          />
          {kitRequest.shippedTimestamp ? (
            "Shipped " + timeago.format(kitRequest.shippedTimestamp)
          ) : (
            <button
              onClick={() => {
                shipKitRequest(kitRequest.id);
              }}
            >
              Mark as shipped
            </button>
          )}
          <Link to={`/app/requests/${kitRequest.id}`}>Show</Link>
          <button
            onClick={() => {
              removeKitRequest(kitRequest.id);
            }}
          >
            Delete
          </button>
        </div>
      ))}
      <RawDataDisplay title="Raw KitRequests" data={kitRequests} />
    </>
  );
};

export default App;
