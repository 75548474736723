/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      placeId
      description
      line1
      line2
      city
      state
      postalCode
      person {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      kitLastReceived
      createdAt
      updatedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      placeId
      description
      line1
      line2
      city
      state
      postalCode
      person {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      kitLastReceived
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      placeId
      description
      line1
      line2
      city
      state
      postalCode
      person {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      kitLastReceived
      createdAt
      updatedAt
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      name
      address {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      name
      address {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      name
      address {
        id
        placeId
        description
        line1
        line2
        city
        state
        postalCode
        kitLastReceived
        createdAt
        updatedAt
      }
      phone
      email
      createdAt
      updatedAt
    }
  }
`;
export const createKitRequest = /* GraphQL */ `
  mutation CreateKitRequest(
    $input: CreateKitRequestInput!
    $condition: ModelKitRequestConditionInput
  ) {
    createKitRequest(input: $input, condition: $condition) {
      id
      requester {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      recipient {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      description
      priority
      shippedTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateKitRequest = /* GraphQL */ `
  mutation UpdateKitRequest(
    $input: UpdateKitRequestInput!
    $condition: ModelKitRequestConditionInput
  ) {
    updateKitRequest(input: $input, condition: $condition) {
      id
      requester {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      recipient {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      description
      priority
      shippedTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteKitRequest = /* GraphQL */ `
  mutation DeleteKitRequest(
    $input: DeleteKitRequestInput!
    $condition: ModelKitRequestConditionInput
  ) {
    deleteKitRequest(input: $input, condition: $condition) {
      id
      requester {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      recipient {
        id
        name
        phone
        email
        createdAt
        updatedAt
      }
      description
      priority
      shippedTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
