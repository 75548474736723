import React from "react";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import Layout from "../components/layout";
import Details from "../components/Details";
import Todos from "../components/Todos";
import Settings from "../components/Settings";
import KitRequests from "../components/KitRequests";
import KitRequest from "../components/KitRequest";
import Home from "../components/Home";
import Login from "../components/Login";
import SignUp from "../components/SignUp";
import PrivateRoute from "../components/PrivateRoute";

const App = () => {
  if (
    typeof window !== `undefined` &&
    (window.location.pathname === "/app" ||
      window.location.pathname === "/app/")
  ) {
    navigate("/app/home", { replace: true });
  }
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/app/home" component={Home} />
        <PrivateRoute path="/app/profile" component={Details} />
        <PrivateRoute path="/app/todos" component={Todos} />
        <PrivateRoute path="/app/settings" component={Settings} />
        <PrivateRoute path="/app/requests" component={KitRequests} />
        <PrivateRoute path="/app/requests/:id" component={KitRequest} />
        <Login path="/app/login" />
        <SignUp path="/app/signup" />
      </Router>
    </Layout>
  );
};

export default App;
