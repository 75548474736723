import React from "react";
import { navigate } from "@reach/router";
import { withStyles } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import { Button } from "@material-ui/core";

import Error from "./Error";
import { formatPhoneNumber } from "../utils/phone";

import { Auth } from "aws-amplify";

const initialState = {
  username: ``,
  password: ``,
  email: "",
  phone_number: "",
  authCode: "",
  stage: 0,
  error: "",
};

const useStyles = (theme) => ({
  input: {
    height: 40,
    margin: "10px 0px",
    padding: 7,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    padding: "15px 7px",
    cursor: "pointer",
    textAlign: "center",
    marginBottom: 10,
  },
});

class SignUp extends React.Component {
  state = initialState;

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  signUp = async () => {
    const {
      username,
      password,
      email,
      phone_number: raw_phone_number,
    } = this.state;
    try {
      const phone_number = formatPhoneNumber(raw_phone_number);
      await Auth.signUp({
        username,
        password,
        attributes: { email, phone_number },
      });
      this.setState({ stage: 1 });
    } catch (err) {
      this.setState({ error: err.message });
      console.log("error signing up...", err);
    }
  };

  confirmSignUp = async () => {
    const { username, authCode } = this.state;
    try {
      await Auth.confirmSignUp(username, authCode);
      alert("Successfully signed up!");
      navigate("/app/login");
    } catch (err) {
      this.setState({ error: err.message });
      console.log("error confirming signing up...", err);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h1>Sign Up</h1>
        {this.state.stage === 0 && (
          <div className={classes.formContainer}>
            {this.state.error && <Error errorMessage={this.state.error} />}
            <input
              onChange={this.handleUpdate}
              placeholder="Username"
              name="username"
              value={this.state.username}
              className={classes.input}
            />
            <input
              onChange={this.handleUpdate}
              placeholder="Password"
              name="password"
              value={this.state.password}
              type="password"
              className={classes.input}
            />
            <input
              onChange={this.handleUpdate}
              placeholder="Email"
              name="email"
              value={this.state.email}
              className={classes.input}
            />
            <input
              onChange={this.handleUpdate}
              placeholder="Phone Number"
              name="phone_number"
              value={this.state.phone_number}
              className={classes.input}
            />
            <Button
              color="primary"
              variant="contained"
              disableElevation
              className={classes.button}
              onClick={this.signUp}
            >
              Sign Up
            </Button>
          </div>
        )}
        {this.state.stage === 1 && (
          <div className={classes.formContainer}>
            {this.state.error && <Error errorMessage={this.state.error} />}
            <input
              onChange={this.handleUpdate}
              placeholder="Authorization Code"
              name="authCode"
              value={this.state.authCode}
              className={classes.input}
            />
            <Button
              color="primary"
              variant="contained"
              disableElevation
              className={classes.button}
              onClick={this.confirmSignUp}
            >
              Confirm Sign Up
            </Button>
          </div>
        )}
        <Link to="/app/login">Sign In</Link>
      </div>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(SignUp);
