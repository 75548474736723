import React from "react";

const Home = () => (
  <div>
    <h1>Dashboard</h1>
    <p>Placeholder for future dashboard</p>
  </div>
);

export default Home;
