import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby-theme-material-ui";
import * as timeago from "timeago.js";
import { Tooltip, makeStyles } from "@material-ui/core";

import { getKitRequest } from "../graphql/queries";
import RawDataDisplay from "./RawDataDisplay";

const useStyles = makeStyles((theme) => ({
  kitRequest: { marginBottom: 15 },
  // input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  kitRequestName: { fontSize: 20, fontWeight: "bold" },
  kitRequestValue: { marginBottom: 0 },
  // button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}));

const Person = (props) => {
  return (
    <>
      <ul>
        <li>{props.name}</li>
        <li>{props.phone}</li>
        <li>{props.email}</li>
      </ul>
      {props.address ? (
        <>
          <b>Address: </b>
          <br />
          {props.address.line1} <br />
          {props.address.line2} <br />
          {props.address.city},{props.address.state} {props.address.postalCode}
        </>
      ) : null}
    </>
  );
};

const App = (props) => {
  const classes = useStyles();
  const [kitRequest, setKitRequest] = useState([]);

  useEffect(() => {
    fetchKitRequest(props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchKitRequest(id) {
    try {
      const kitRequestData = await API.graphql(
        graphqlOperation(getKitRequest, { id })
      );
      const kitRequest = kitRequestData.data.getKitRequest;
      setKitRequest(kitRequest);
    } catch (err) {
      console.log("error fetching kitRequest");
    }
  }

  return (
    <>
      <Link to="/app/requests">Back to list</Link>
      <h2>Request</h2>
      <div key={kitRequest.id} className={classes.kitRequest}>
        <p>
          <b>ID:</b> {props.id} <br />
          <b>Description:</b> {kitRequest.description}
        </p>
        <p>
          <b>Created: </b>
          <Tooltip title={kitRequest.createdAt} aria-label="created">
            <span>{timeago.format(kitRequest.createdAt)}</span>
          </Tooltip>
          <br />
          <b>Modified: </b>
          <Tooltip title={kitRequest.updatedAt} aria-label="updated">
            <span>{timeago.format(kitRequest.updatedAt)}</span>
          </Tooltip>
          <br />
          <b>Shipped: </b>
          {kitRequest.shippedTimestamp ? (
            <Tooltip
              title={kitRequest.shippedTimestamp}
              aria-label="shippedTimestamp"
            >
              <span>{timeago.format(kitRequest.shippedTimestamp)}</span>
            </Tooltip>
          ) : (
            "Not shipped"
          )}
        </p>
        <h3>Requested by</h3>
        <Person {...kitRequest.requester} />

        <h3>Recipient</h3>
        <Person {...kitRequest.recipient} />
      </div>
      <RawDataDisplay title="Raw KitRequests" data={kitRequest} />
    </>
  );
};

export default App;
