import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core";
import {
  createSetting,
  deleteSetting,
  updateSetting,
} from "../graphql/mutations";
import { listSettings } from "../graphql/queries";
import RawDataDisplay from "./RawDataDisplay";

const initialState = { name: "", value: "", id: "" };

const useStyles = makeStyles((theme) => ({
  setting: { marginBottom: 15 },
  // input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  settingName: { fontSize: 20, fontWeight: "bold" },
  settingValue: { marginBottom: 0 },
  // button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}));

const App = () => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    fetchSettings();
  }, []);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function fetchSettings() {
    try {
      const settingData = await API.graphql(graphqlOperation(listSettings));
      const settings = settingData.data.listSettings.items;
      setSettings(settings);
    } catch (err) {
      console.log("error fetching settings");
    }
  }

  async function addOrUpdateSetting() {
    try {
      if (!formState.name || !formState.value) return;
      const setting = { ...formState };
      setFormState(initialState);

      if (setting.id !== "") {
        const results = await API.graphql(
          graphqlOperation(updateSetting, {
            input: {
              id: setting.id,
              name: setting.name,
              value: setting.value,
            },
          })
        );
        const filteredSettings = settings.filter(
          (item) => item.id !== setting.id
        );
        setSettings([...filteredSettings, results.data.updateSetting]);
      } else {
        const results = await API.graphql(
          graphqlOperation(createSetting, {
            input: { name: setting.name, value: setting.value },
          })
        );
        setSettings([...settings, results.data.createSetting]);
      }
    } catch (err) {
      console.log("error creating or updating setting:", err);
    }
  }

  async function editSetting(id) {
    const setting = settings.find((item) => item.id === id);
    setFormState(setting);
  }

  async function removeSetting(id) {
    if (typeof id === "undefined") {
      return;
    }

    try {
      await API.graphql(graphqlOperation(deleteSetting, { input: { id } }));

      const updatedSettings = settings.filter((item) => item.id !== id);
      setSettings(updatedSettings);
    } catch (err) {
      console.log("Error removing setting:", err);
    }
  }

  return (
    <>
      <h2>Site Settings</h2>
      <input
        onChange={(event) => setInput("name", event.target.value)}
        className={classes.input}
        value={formState.name}
        placeholder="Name"
      />
      <input
        onChange={(event) => setInput("value", event.target.value)}
        className={classes.input}
        value={formState.value}
        placeholder="Value"
      />
      <input
        className={classes.input}
        value={formState.id}
        disabled
        placeholder="ID"
      />
      <button className={classes.button} onClick={addOrUpdateSetting}>
        Save Setting
      </button>
      {settings.map((setting, index) => (
        <div key={setting.id ? setting.id : index} className={classes.setting}>
          <p className={classes.settingName}>{setting.name}</p>
          <p className={classes.settingValue}>{setting.value}</p>
          <button
            onClick={() => {
              editSetting(setting.id);
            }}
          >
            Edit
          </button>
          <button
            onClick={() => {
              removeSetting(setting.id);
            }}
          >
            Delete
          </button>
        </div>
      ))}
      <RawDataDisplay title="Raw Settings" data={settings} />
    </>
  );
};

export default App;
